.skeleton-ts .skeleton-img--ts {
    width: 60px;
    height: 60px;
    background-color: #e0e0e0;
    border-radius: 50%;
    animation: skeleton-loading 1.5s infinite;
  }
  
  .skeleton-ts .skeleton-text--ts {
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin: 5px 0;
    padding-top: 10px;
    animation: skeleton-loading 1.5s infinite;
  }
  
  .skeleton-ts .name {
    width: 80px;
  }
  
  .skeleton-ts .eth {
    width: 40px;
  }

  .author-list-ts {
    padding-left: 80px;
    padding-bottom: 10px;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #c0c0c0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  