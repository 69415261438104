/* SLICK SLIDER */

.slick-slide {
  padding: 5px;
}

.slick-arrow {
  transition: background-color 0.3s ease-in-out, transform 0.3s ease;
}

.slick-next:hover,
.slick-prev:hover {
  background-color: lightgray;
  transform-origin: center;
}

.img-main {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}



/* SKELETON */

.skeleton-image {
  width: 100%;
  height: 200px;
  background-color: #ccc;
  margin-bottom: 10px;
}

.checkmark {
  position: relative;
  margin-bottom: -5px;
}

.skeleton-author-image {
  width: 50px;
  height: 50px;
  background-color: #d0d0d0;
  border-radius: 50%;
}

.skeleton-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-title {
  width: 30%;
  height: 20px;
  margin-top: 10px;
  background-color: #d0d0d0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.skeleton-code {
  width: 20%;
  height: 15px;
  margin-top: 10px;
  background-color: #d0d0d0;
  border-radius: 5px;
}
