.item-details-skeleton {
  display: flex;
  gap: 20px;
  padding: 50px;
  margin-top: 120px;
}

.poster-id {
  top: 40px;
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}

.skeleton-box-id {
  background-color: #ddd;
  border-radius: 4px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-poster-id {
  height: 500px;
  width: 100%;
}

.details-id {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-title-id {
  width: 50%;
  height: 40px;
  margin-bottom: 10px;
}

.skeleton-info-counts-id {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.skeleton-info-box {
  height: 30px;
  width: 20%;
}

.skeleton-description-id {
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
}

.skeleton-author-title-id {
  height: 15px;
  width: 10%;
  border-radius: 4px;
}

.skeleton-author-id {
  display: flex;
  align-items: center;
}

.skeleton-author-image-id {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.skeleton-author-name-id {
  width: 20%;
  height: 20px;
  margin-left: 10px;
}

.skeleton-price {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.skeleton-price-image-id {
  height: 25px;
  width: 25px;
  border-radius: 50px;
}

.skeleton-price-amount-id {
  width: 15%;
  height: 20px;
  margin-left: 10px;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@media (max-width: 767px) {
  .item-details-skeleton {
    flex-direction: column;
  }
}
