@media (max-width: 576px) {
    .nft__item_wrap img {
        max-width: 80%;
        height: auto;
        margin: 0 auto;
    }
}

.skeleton-ni {
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-ni::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-150px);
    }
    100% {
      transform: translateX(150px);
    }
  }
  
  .skeleton-image--ni {
    width: 100%;
    height: 200px;
    border-radius: 8px;
  }
  
  .skeleton-text--ni {
    height: 16px;
    margin-bottom: 10px;
  }
  
  .skeleton-title--ni {
    margin-top: 5px;
    width: 60%;
  }
  
  .skeleton-price--ni {
    width: 40%;
  }
  
  .skeleton-like--ni {
    width: 40px;
    height: 20px;
  }
  
  .skeleton-circle--ni {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  